.analytics-screen{
    .number-text{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #E54D3A;
    }
    .sub-text{
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.01em;
        color: #757575;
    }
}