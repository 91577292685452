@import "./variables.scss";

body {
  color: var(--color-text)
}

.no-gutter {
  [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}

.primary-bg {
  background: transparent url(../images/login-background.png);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-headings)
}

.fs-36 {
  font-size: 36px;
}

.fs-18 {
  font-size: 18px;
}