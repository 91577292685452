.dropdown-datepicker {
  &-wrap {
    position: relative;
  }

  .dropdown-toggle {
    align-items: center;
    display: flex;
    padding: 0;
    width: 100%;
    
    &::after {
      pointer-events: none;
      position: absolute;
      right: var(--bs-btn-padding-x);
      touch-action: none;
    }

    .react-datepicker__input-container input {
      border: 0;
      color: #505D67;
      height: auto;
      padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
      padding-right: 1.6em;
      width: calc(110px + 1.6em);
    }
  }

  .react-datepicker__navigation-icon::before {
    pointer-events: none;
    top: 13px;
    touch-action: none;
  }
}

.p-datepicker {

  &--label {
    padding-left: 2em;
    position: relative;

    &::before {
      background: transparent url(../../assets/images/icon-calendar.png) center center / contain no-repeat;
      content: '';
      display: block;
      height: 1.4em;
      left: 0;
      position: absolute;
      top: 40%;
      transform: translateY(-50%);
      width: 1.4em;
    }
  }

  .react-datepicker__navigation-icon::before {
    pointer-events: none;
    top: 13px;
    touch-action: none;
  }
}