.drop-down-btn{
    border: 1px solid #ced4da;
    color: #6F6F6F !important;
    outline: none;
    box-shadow: none !important;
    &::after{
        margin-left: auto !important;
        margin-right: 0.255em;
    }
}

.dropdown-null-option{
    font-weight: bold;
}