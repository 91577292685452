:root {
  --color-white: #fff;

  --color-primary-accent: #f2994a;
  --color-page-heading: #2c2929;
  --color-headings: #535356;
  --color-text: #898989;
  --color-border: rgba(79, 79, 79, 0.12);
  --color-tile-button: #535763;
  --color-tile-button-active: var(--color-primary-accent);
}
