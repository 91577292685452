.access-code-name{
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #2C2929;
}

.small-label{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #F2994A;
}

.delivery-address{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #757575;
}

.carrier-call-and-email{
    min-width: 420px;
    .carrier-contact-text{
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.01em;
        color: #2C2929;
    }
}


.map-image-placeholder{
    width: 1027px;
    height: 400px;
}

.delivery-notes-head{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2C2929;
}

.delivery-notes{
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #2C2929;
    max-width: 650px;
}