.login-bg {
  background-repeat: no-repeat;
  background-size: cover;

  .backdrop {
    background-color: black;
    opacity: 0.5;
    z-index: 0;
  }

  img {
    z-index: 100;
  }

  .login-welcome-text {
    color: white;
    font-family: 'Lato';
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 62px;
    max-width: 450px;
    position: relative;
    z-index: 1;
  }
}

.login-form-container {
  .login-form {
    width: 450px;

    .welcome-text-form {
      color: #898989;
    }

    .dv-input-prefix {
      background: #F6F6F6;
      border: 0;
    }

    .dv-form-input {
      border: 0;
      height: 64px;
      background: #F6F6F6;
      outline: none !important;
      box-shadow: none !important;
      color: #898989 !important;

      &.input-with-prefix {
        border-left: 0;
      }

      &:focus {
        border-color: #ced4da;
      }

      &::placeholder {
        color: #898989;
      }
    }
    .custom-submit {
      position: relative;
        top: 150px;
    }
    .map-container {
      position: relative;
        top: -183px;
    }
  }

  .login-terms {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    a:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 768px) {}