.user-select-card{
    width: 180px;
    height: 190px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    font-weight: 500;
    p{
        font-size: 15px;
        line-height: 22px;
    }
    &:hover{
        box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.1);
    }
    &.selected{
        border: 1px solid rgba(242, 153, 74, 0.41);
    }
}

