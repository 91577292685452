.login-bg{
    background-repeat: no-repeat;
    background-size:100% 100%;
    .backdrop{
        background-color: black;
        opacity: 0.5;
        z-index: 10;
    }
    img{
        z-index: 100;
    }
    .spacer-parent{
        height: calc(100% - 100px);
        .spacer{
            flex-grow: 1;
        }
        .login-welcome-text{
            color:white;
            max-width: 450px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 62px;
            z-index: 100;
            margin-bottom: 200px;
        }
    }
}

.login-form-container{
    .login-form{
        width: 450px;
        .welcome-text-form{
            color:#898989;
        }
        .dv-input-prefix{
            background: #F6F6F6;
            border:0;
        }
        .dv-form-input{
            border:0;
            height: 64px;
            background: #F6F6F6;
            outline: none !important;
            box-shadow: none !important;
            color:#898989 !important;
            &.input-with-prefix{
                border-left: 0;
            }
            &:focus{
                border-color: #ced4da;
            }
            &::placeholder{
                color:#898989;
            }
        }
        
    }
    .login-terms{
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        a:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 768px){
    
}