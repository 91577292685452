.popup-display-field-container{
    margin-top: 16px;
    .field-head{
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #4F4F4F;
        margin-bottom: 0;
    }
    .field-description{
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: #2C2929;
    }
}

.btn-grp{
    .cancel-btn{
        border: 1px solid rgba(67, 67, 67, 0.12);
        color: #F2994A;
    }
    .reply-btn{
        border-radius: 0;
    }
}