h3 {
  font-size: 18px;
  font-weight: 600;
}

.form-group {
  .form-label {
    color: #3c3838;
    font-size: 14px;
    font-weight: 600;
  }
}
