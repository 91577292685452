.indicator-bar {
  border-radius: 1px;
  width: 64px;
  height: 12px;
  margin-left: 25px;
}

.indicator-text {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #828282;
}

.index--bar-chart {
  background-color: var();
  display: flex;
  flex-direction: column;
  height: 46px;
  margin: 24px 0 0 10px;
  position: relative;
  width: 22px;

  .label {
    position: relative;

    &:before {
      background-color: #b8b8b8;
      content: "";
      display: block;
      height: 2px;
      left: 100%;
      margin-left: 7px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 36px;
    }

    &:after {
      color: #828282;
      content: "Low";
      display: block;
      font-size: 9px;
      font-weight: 400;
      left: 100%;
      line-height: 15px;
      margin-left: 50px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .low {
    &:after {
      content: "Low";
    }
  }
  .space-1 {
    flex: 1;
  }
  .avg {
    flex: auto;
    flex-basis: 3px;
    max-height: 3px;
    &:after {
      content: "Avg";
    }
  }
  .space-2 {
    flex: 1.4;
  }
  .high {
    &:after {
      content: "High";
    }
  }
}
