form {
    /*margin: 0 -2%;*/

    .dv-default-input-container {
        margin-top: 16px;
        padding: 2%;

        .dv-form-input {
            height: 46px;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.01em;
            color: #6f6f6f;

            &:focus {
                color: black;
            }
        }
        .invalid-tooltip {
            top: 18%;
        }
        &.half {
            width: 50%;
            display: inline-block;
        }
    }
}
