
.multiline-container {
    /* background-color: #000000; */
    /* border: 1px solid red; */
}

.axisX text {
    text-anchor: start;
}

.baseGridLine line {
    stroke: rgba(245, 6, 6, 0.5);
}

.multi-tooltip text {
    font-size: 12px;
    fill: #000;
}

.tooltipLine {
    stroke: #ccc;
    stroke-width: 2px;
}

.tooltipContent .contentTitle {
    font-weight: bold;
}

.tooltipLinePoint {
    fill: #000;
}

.legendContainer {
    display: flex;
    justify-content: center;
}

.checkbox {
    margin: 10px;
}