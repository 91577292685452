.dv-header{
    height: var(--dashboard-header-height);
    background-color: var(--dashboard-header-bg);
    border-bottom: 1px solid #EFEFEF;
    .dashboard-header-search-box{
        .dv-input-prefix {
            background-color: var(--search-input-bg);
            border:0;
        }
        .dashboard-header-search{
            color: var(--search-input-text-color);
            max-width: 405px;
            border:0 !important;
            background-color: var(--search-input-bg);
            &:focus{
                box-shadow: none;
                outline: none;
            }
        }
    }
    .header-profile-info{
        width: 180px;
        .profile-name{
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
        }
        .profile-role{
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
        }
    }

}
