.id-image-drag-drop{
    height: 468px;
    width: 462px;
    border: 1px dotted black;

    > img{
        width: 48px;
        cursor: pointer;
    }
    .dv-btn{
        height: 45px;
    }
}

.continue-btn.id-verify{
    width:462px;
}