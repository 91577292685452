.order-dropdown {
    .dropdown-toggle {
        color: #000;
        text-decoration: none;
        font-size: 20px;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .btn:focus {
        outline: 0;
        box-shadow: none;
    }
}


.nav-tabs {
    border-bottom: none;

    .nav-item {
        .nav-link {
            color: #000;
            background-color: transparent;
            border-color: none;
            border: 0;
        }

        .nav-link.active {
            color: gray;
            border-bottom: 2px solid #F2994A;
        }

        .nav-link:hover {
            color: gray
        }
    }
}

.btn-custom {
    background-color: #F2994A;
    color: #fff;
}

.btn-custom:focus {
    box-shadow: none;
}