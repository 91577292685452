.btn-custom {
    background-color: #F2994A;
    color: #fff;
}

.authorised-area {
    border: 1px solid #F2994A;
    background: rgba(242, 153, 74, 0.23);
    border-radius: 100%;
    padding: 10px;
}