.dv-side-nav{
    width: 290px;
    overflow-y: auto;
    .nav-content{
        // min-height: 500px;
        .dv-nav{
            cursor: pointer;
            &.selected{
                background: var(--selected-nav-bg);
                border-left: var(--selected-nav-icon-color);
                color: var(--selected-nav-icon-color);
                border-right: 3px solid  var(--selected-nav-icon-color);
            }
        }
    }
   
}