.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
}

.google-map {
    /* width: 100%; */
    height: 250px;
}

@media screen and (min-width: 799px) {
    .google-map {
        /* height: 80vh; */
    }
}