.dv-table {
    width: 100%;

    thead {
        background: #F1F2F3;
        height: 65px;

        .dv-row {
            .dv-table-head {
                padding: 2px 16px;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: #6B7280;
                text-align: center;
            }
        }
    }

    tbody {
        .dv-row {
            border-bottom: 1px solid rgba(224, 224, 224, 0.6);

            .dv-table-cell {
                padding: 16px;
                text-align: center;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                color: #4F4F4F;

                .profile-name {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    color: #4F4F4F;
                }

                .profile-email {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 20px;
                    color: #828282;
                }

                .table-actions-container {
                    background: #F1F2F3;
                    border-radius: 10px;
                    padding: 0 12px;
                    width: 150px;
                }
            }
        }
    }
}

.table-hr {
    height: 1px;
    background: #E5E7EB;
}

.pagination-details {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
}

.pagination-btns {
    .page-btn {
        width: 92px;
    }

    .no-right-border {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .no-left-border {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.action-cell {
    cursor: pointer;
}