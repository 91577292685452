.view-user-screen{
    .user-profile{
        border-right:0.4px solid #B6B6B6;
    
        .profile-field-head{
            font-style: normal;
            font-weight: 500;
            text-align: center;
            color: #2E3034;
            font-size: 18px;
            line-height: 26px;
            &.large{
                font-size: 24px;
                line-height: 35px;
            }
        }
        .profile-field-value{
            text-align: center;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #717273;
        }
    }
    .access-area-list{
        .head{
            border-bottom: 0.4px solid #B6B6B6;
        }
        .access-area-list-item{
            border-bottom: 0.4px solid #B6B6B6;
            border-left: 0.4px solid #B6B6B6;
        }
    }
}

.give-access-btn{
    border:#F2994A 1px solid;
    color:#F2994A;
    height: 36px;
    border-radius: 36px;
}