.outlet-container {
  padding: 36px 27px 27px 36px;
  overflow-y: auto;
  height: calc(100vh - var(--dashboard-header-height));
}

.section-heading {
  margin: 35px 0;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  color: #4f4f4f;
}

.page-heading {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  .section-heading {
    color: var(--color-page-heading);
    margin: 0;
  }
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 0;

    :nth-child(n + 2) {
      margin-left: 0.5em;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    .current-page {
      color: var(--color-primary-accent);
    }
  }
}

.icon {
  &-19 {
    height: 19px;
    width: 19px;
  }
}
