.dv-btn {
  background-color: #f2994a !important;
  border-radius: 6px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  height: 64px;
  line-height: 64px;
  text-transform: uppercase;
  width: 100%;

  &.btn:disabled,
  &:disabled {
    color: rgba(255, 255, 255, 0.5);
  }
}
